import Service from "../Service";
const resource = "articles/";

export default {
    save(art, requestID) {
        return Service.post(resource + "save", art, { params: { requestID: requestID }, });
    },

    listarticlesap(params, requestID) {
        console.log('listarticlesap ', params);
        return Service.post(resource + "listarticlesap", params, {
            params: { requestID }
        })
    },

    updatedayunlock(params, requestID) {
        console.log('listarticlesap ', params);
        return Service.post(resource + "updatedayunlock", params, {
            params: { requestID }
        })
    },
};