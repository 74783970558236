<template>
	<div>
		<v-container>
			<v-row>
				<v-col>
					<s-toolbar
						label="Liberación Equipo"
					></s-toolbar>
					<v-data-table
						:headers="headers"
						:items="itemsArticle"
						item-key="ArtCode"
						search-input
						:search="search"
					>
						<template v-slot:top>
							<v-text-field
								v-model="search"
								label="Search"
								class="mx-4"
							></v-text-field>
						</template>

						<template v-slot:item.ArtDaysFree="{ item }">
							<v-btn
								x-small
								color="success"
								@click="dialogEditDayFree(item)"
							>
								<i class="fas fa-pencil-alt"></i>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog
			v-model="dialogDayFree"
			width="400"
		>
			<s-toolbar
				label="Dias para desbloquear"
				color="'#BAB6B5'"
				save
				@save="save()"
				close
				@close="closeDialogDayFree()"
			></s-toolbar>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-text
								label="Dias"
								number
								v-model="ArtDayUnlock"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sArticle from "@/services/Logistics/ArticleService.js";

	export default {
		data() {
			return {
				ArtDayUnlock : 0,
				articleSelected : {},
				dialogDayFree : false,
				searchBool : true,
				search : "",
				itemsArticle: [],
				filter : {
					ArtCode : "",
					ArtDescription : ""
				},
				headers : [
					{ text: "Código", value: "ArtCode" },
					{ text: "Descripción", value: "ArtDescription" },
					{ text: "Stock", value: "ArtStock" },
					{ text: "Dias", value: "ArtDayUnlock" },
					{ text: "Dias desbloqueo", value: "ArtDaysFree" },
				]
			}
		},

		methods: {
			save()
			{
				if(this.ArtDayUnlock < 0)
				{
					this.$fun.alert("Número de dias incorrecto");
					return;
				}

				this.articleSelected.ArtDayUnlock = this.ArtDayUnlock;
				_sArticle
				.updatedayunlock(this.articleSelected, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Actualizado correctamente", "success");
						this.closeDialogDayFree();
						return;
					}
				})
			},

			closeDialogDayFree()
			{
				this.dialogDayFree = false;
			},

			dialogEditDayFree(item)
			{
				this.dialogDayFree = true;
				this.articleSelected = item;
				this.ArtDayUnlock = item.ArtDayUnlock;
			},

			getItemsArticles() {
				_sArticle
				.listarticlesap(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsArticle = resp.data;
					}
				})
			}
		},

		created () {
			this.getItemsArticles();
		},
	}
</script>
